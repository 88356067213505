import React from "react";
import { Typography, Box } from "@material-ui/core";

const Kontakt = () => {
  return (
    <>
      <Box m={8} />
      <Typography variant="h5">KONTAKT</Typography>

      <Box m={5} />

      <Typography>Napsat nám můžete na e-mail napis@co-pak.cz.</Typography>
      <Box m={5} />
      <Typography>
        Jsme taky na{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/copak.umcoapravo/"
        >
          Facebooku
        </a>{" "}
        a{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/copak_umcoapravo/"
        >
          Instagramu
        </a>
        .{" "}
      </Typography>

      <Box m={8} />
    </>
  );
};

export default Kontakt;
