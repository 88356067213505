import React from "react";
import {
  AppBar,
  Typography,
  makeStyles,
  Toolbar,
  Grid,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import imgLogo from "./assets/logo_male.png";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    align: "center",
  },
  headerStyle: {
    //backgroundColor: "#F2F2F2",
    backgroundColor: "#fafafa",
    color: "#000000",
  },
  menuItem: {
    textAlign: "center",
    margin: "auto",
  },
  lastMenuItem: {
    textAlign: "right",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  jungleOnHover: {
    "&:hover": {
      color: "#29AB87",
    },
  },

  logoButton: {
    color: "black",
    textDecoration: "none",
    "&:visited": {
      textDecoration: "none",
    },
  },

  inactiveMenuButton: {
    textDecoration: "none",
    "&:visited": {
      textDecoration: "none",
    },
    "&:hover": {
      color: "#29AB87",
    },
    color: "black",
  },

  activeMenuButton: {
    color: "#29AB87",
  },
}));

function Header() {
  const classes = useStyles();
  return (
    <AppBar elevation={0} position="static" className={classes.headerStyle}>
      <Toolbar disableGutters>
        <Grid container spacing={0}>
          <Grid item xs={false} md={1} xl={3} />
          <Grid item sm={4} md={4} xl={2} className={classes.menuItem}>
            <NavLink to="/" className={classes.logoButton}>
              {/* <Button disableElevation variant="text" size="large"> */}
              <Typography
                align="left"
                noWrap
                variant="h4"
                className={classes.title}
              >
                {/* <img src={imgLogo} /> */}
                co&mdash;pak
              </Typography>
              {/* </Button> */}
            </NavLink>
          </Grid>
          <Grid item xs={12} sm={8} md={6} xl={4} container>
            <Grid item xs={12} md={3} className={classes.menuItem}>
              <Button>
                <NavLink
                  to="/kdojsme"
                  className={classes.inactiveMenuButton}
                  activeClassName={classes.activeMenuButton}
                >
                  <Typography>KDO JSME</Typography>
                </NavLink>
              </Button>
            </Grid>
            <Grid item xs={12} md={3} className={classes.menuItem}>
              <Button>
                <NavLink
                  to="/codelame"
                  className={classes.inactiveMenuButton}
                  activeClassName={classes.activeMenuButton}
                >
                  <Typography>CO DĚLÁME</Typography>
                </NavLink>
              </Button>
            </Grid>
            <Grid item xs={12} md={3} className={classes.menuItem}>
              <Button>
                <NavLink
                  to="/kdysepotkame"
                  className={classes.inactiveMenuButton}
                  activeClassName={classes.activeMenuButton}
                >
                  <Typography>KDY SE POTKÁME</Typography>
                </NavLink>
              </Button>
            </Grid>
            <Grid item xs={12} md={3} className={classes.menuItem}>
              <Button
                variant="outlined"
                size="large"
                className={classes.jungleOnHover}
              >
                <NavLink
                  to="/kontakt"
                  className={classes.inactiveMenuButton}
                  activeClassName={classes.activeMenuButton}
                >
                  <Typography>KONTAKT</Typography>
                </NavLink>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={false} md={1} xl={3} />
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
