import React, { useState } from "react";
import { makeStyles, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  img: {
    width: 225,
    height: 225,
  },
  FAQ__question: {
    textAlign: "left",
    fontSize: "14px",
    margin: "auto",
  },
  FAQ__answer_hidden: {
    display: "none",
  },
}));

function FAQ({
  children,
  defaultOpen = "false",
  open: openFromProps,
  onToggle: onToggleFromProps = () => {},
}: any) {
  const isControlled = () => (openFromProps ? true : false);
  const [open, setIsOpen] = useState(defaultOpen);
  const getOpen = () => (isControlled() ? openFromProps : open);
  const isOpen = (index: any) => {
    return getOpen().includes(index) ? true : false;
  };
  const onToggle = (index: any) => {
    if (isControlled()) {
      onToggleFromProps(index);
    } else {
      if (getOpen().includes(index)) {
        setIsOpen(getOpen().filter((item: any) => item !== index));
      } else {
        setIsOpen([...getOpen(), index]);
      }

      onToggleFromProps(index);
    }
  };
  return (
    <dl>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          isOpen: isOpen(index),
          onToggle: () => onToggle(index),
        });
      })}
    </dl>
  );
}

function Question({ children, isOpen, answerId, onToggle }: any) {
  const classes = useStyles();
  return (
    <dt>
      <Button
        className={classes.FAQ__question}
        aria-expanded={isOpen}
        aria-controls={answerId}
        onClick={onToggle}
        disableTouchRipple
      >
        {children(isOpen, onToggle)}
      </Button>
    </dt>
  );
}

function Answer({ children, id, isOpen }: any) {
  const classes = useStyles();

  return (
    <dd>
      <p className={isOpen ? "" : classes.FAQ__answer_hidden} id={id}>
        {children}
      </p>
    </dd>
  );
}

function QAItem({ children, isOpen, onToggle }: any) {
  return React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      isOpen: isOpen,
      onToggle: onToggle,
    });
  });
}

FAQ.QAItem = QAItem;
FAQ.Question = Question;
FAQ.Answer = Answer;

export default FAQ;
