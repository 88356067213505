import React from "react";
import FAQ from "../FAQ";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";

const CoDelame = () => {
  return (
    <>
      <Box m={8} />
      <Typography variant="h5">CO DĚLÁME?</Typography>

      <Box m={5} />

      <Typography variant="h5">
        Přednášky pro mladé tvůrce o autorském právu. Zadarmo.
      </Typography>

      <Box m={5} />

      <FAQ>
        <FAQ.QAItem>
          <FAQ.Question answerId="q1">
            {(isOpen: any, onToggle: any) => {
              return (
                <>
                  {isOpen ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                  <Typography component={"span"}>Co teda děláte?</Typography>
                </>
              );
            }}
          </FAQ.Question>
          <FAQ.Answer id="q1">
            <Typography component={"span"}>
              Vysvětlujeme právo. Připravily jsme pro vás{" "}
              <Link to="/kdysepotkame"> přednášky</Link>, kde si řekneme to
              nejzákladnější. Jednoduše a srozumitelně.
            </Typography>
          </FAQ.Answer>
        </FAQ.QAItem>
        <Box m={1} />
        <FAQ.QAItem>
          <FAQ.Question answerId="q2">
            {(isOpen: any, onToggle: any) => {
              return (
                <>
                  {isOpen ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                  <Typography component={"span"}>
                    Proč bych měl/a přijít?
                  </Typography>
                </>
              );
            }}
          </FAQ.Question>
          <FAQ.Answer id="q2">
            <Typography component={"span"}>
              Protože až přednášky skončí, smlouva už pro vás nebude strašák. A
              pokud někdo použije váš výtvor bez vašeho souhlasu, budete vědět,
              co s tím.
            </Typography>
          </FAQ.Answer>
        </FAQ.QAItem>

        <Box m={1} />
        <FAQ.QAItem>
          <FAQ.Question answerId="q3">
            {(isOpen: any, onToggle: any) => {
              return (
                <>
                  {isOpen ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                  <Typography component={"span"}>Kdy a kde?</Typography>
                </>
              );
            }}
          </FAQ.Question>
          <FAQ.Answer id="q3">
            <Typography component={"span"}>
              Klikněte si na{" "}
              <Link to="/kdysepotkame" style={{ textDecoration: "none" }}>
                KDY SE POTKÁME.
              </Link>
            </Typography>
          </FAQ.Answer>
        </FAQ.QAItem>

        <Box m={1} />
        <FAQ.QAItem>
          <FAQ.Question answerId="q4">
            {(isOpen: any, onToggle: any) => {
              return (
                <>
                  {isOpen ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                  <Typography component={"span"}>
                    Proč je to zadarmo?
                  </Typography>
                </>
              );
            }}
          </FAQ.Question>
          <FAQ.Answer id="q4">
            <Typography component={"span"}>
              <Box fontStyle="italic"></Box>
              <Box m={1} />
              Protože víme, že začínající tvůrce nemá peněz nazbyt. Zároveň se
              těšíme, že se něco naučíme i my od vás.
            </Typography>
          </FAQ.Answer>
        </FAQ.QAItem>

        <Box m={1} />
        <FAQ.QAItem>
          <FAQ.Question answerId="q5">
            {(isOpen: any, onToggle: any) => {
              return (
                <>
                  {isOpen ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                  <Typography component={"span"}>
                    Mám právní problém, poradíte mi?
                  </Typography>
                </>
              );
            }}
          </FAQ.Question>
          <FAQ.Answer id="q5">
            <Typography component={"span"}>
              Neporadíme. Poradíme vám ale, jak se do právních problémů
              nedostat.
              <Box m={2} />
              Nejsme advokáti a právní rady v konkrétní kauze vám může
              poskytnout jen advokát. Toho svého si můžete najít na stránkách{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://vyhledavac.cak.cz/"
              >
                advokátní komory
              </a>
              . Pokud se bojíte ozvat přímo advokátovi, je tady třeba{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.fairart.cz/"
              >
                Fair Art
              </a>
              .
            </Typography>
          </FAQ.Answer>
        </FAQ.QAItem>

        <Box m={1} />
        <FAQ.QAItem>
          <FAQ.Question answerId="q7">
            {(isOpen: any, onToggle: any) => {
              return (
                <>
                  {isOpen ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                  <Typography component={"span"}>Bude to nuda?</Typography>
                </>
              );
            }}
          </FAQ.Question>
          <FAQ.Answer id="q7">
            <Typography component={"span"}>Ne.</Typography>
          </FAQ.Answer>
        </FAQ.QAItem>

        <Box m={1} />
        <FAQ.QAItem>
          <FAQ.Question answerId="q8">
            {(isOpen: any, onToggle: any) => {
              return (
                <>
                  {isOpen ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                  <Typography component={"span"}>Proč CO-PAK?</Typography>
                </>
              );
            }}
          </FAQ.Question>
          <FAQ.Answer id="q8">
            <Typography component={"span"}>
              CO-PAK znamená Cyklus o právu pro autory a kreativce.
            </Typography>
          </FAQ.Answer>
        </FAQ.QAItem>
      </FAQ>
    </>
  );
};

export default CoDelame;
