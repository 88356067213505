import React from "react";
import axios from "axios";
import {
  Box,
  Typography,
  makeStyles,
  Card,
  CardHeader,
  Avatar,
  CardContent,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { red } from "@material-ui/core/colors";

interface IProps {}

interface IFormData {
  email: string;
}

interface IState {
  formData: IFormData;
  submitted: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    backgroundColor: "lightgray",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    color: "white",
    backgroundColor: "#29AB87",
  },
  strike: {
    textDecoration: "line-through",
  },
}));

// export default class KdySePotkame extends React.Component<IProps, IState> {
const KdySePotkame = () => {
  const classes = useStyles();

  // state = {
  //   formData: {
  //     email: "",
  //   },
  //   submitted: false,
  // };

  // emailRef: any = React.createRef();

  // handleSubmit = (event: any): void => {
  //   debugger;
  //   this.setState({ submitted: true }, () => {
  //     setTimeout(() => this.setState({ submitted: false }), 5000);
  //   });

  //   const emailToSendRef = { Email: this.emailRef };
  //   const emailToSend = this.state.formData.email;
  //   debugger;
  //   axios.post("https://localhost:44371/emailcollector", emailToSend, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   });
  // };

  // handleChange = (event: any): void => {
  //   const { formData } = this.state;
  //   formData.email = event.target.value;
  //   this.setState({ formData });
  // };

  // handleBlur = (event: any) => {
  //   this.emailRef.current.validate(event.target.value);
  // };

  // render() {
  //   const { formData, submitted } = this.state;

  return (
    <>
      <Box m={8} />
      <Typography variant="h5">KDY SE POTKÁME?</Typography>
      <Box m={5} />
      <Typography>
        <Box m={4} />
        Teď bohužel nemáme nic naplánovaného.
        <Box m={4} />
        Sledujte náš web, Instagram, Facebook pro nové termíny přednášek.
        Nebo nám nechte svůj e-mail a dáme vědět, až se otevře přihlašování.
        {/* Přihlašovat se můžete přes {" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfH_V8KR6GbKDo75J2bYD-zfvfBB9eafPbzJKaPowhPWdZ86g/viewform?usp=pp_url"
              >
                formulář
              </a>. */}
        <Box m={8} />
        <Typography variant="h6">
          PROBĚHLO:
        </Typography>
        {/* <Box m={4} />
        <Box fontWeight="fontWeightBold">
          Umčo a právo I.: 12.10.2020 od 18:30 do 20:30
          <Box m={2} />
        </Box>
        Autorské právo pro začátečníky
        <br />
        Jsem autor? Znám svá práva? Co je to licence?
        <Box m={6} />
        <Box fontWeight="fontWeightBold">
          Umčo a právo II.: 19.10.2020 od 18:30 do 20:30
          <Box m={1} />
        </Box>
        Autorské právo pro pokročilé <br />
        Kdy můžu použít cizí dílo? Kdy mohou jiní použít mé dílo? Jaké jsou
        právní limity tvorby (osobní údaje, ochranné známky)?
        <Box m={6} />
        <Box fontWeight="fontWeightBold">
          Umčo a právo III.: 26.10.2020 od 18:30 do 20:30
        </Box>
        <Box m={1} />
        Tvůrce a smlouvy
        <br />
        Musím něco podepsat, abych uzavřel smlouvu? Tvořím jako zaměstnanec,
        tvořím na IČO – je v tom rozdíl?
        <Box m={6} /> */}
      </Typography>

      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar variant="rounded" className={classes.avatar}>
              I.
            </Avatar>
          }
          title="Umčo a právo I."
          subheader="7. 9. 2021, 18:30—20:30, Praha 1"
          // subheaderTypographyProps={{ className: classes.strike }}
        />
        <CardContent>
          <Typography variant="body2" component="p">
            {/* <b>ODLOŽENO</b>
            <br />
            <br /> */}
            Autorské právo pro začátečníky
            <br />
            {/* <br />
            Jsem autor? Znám svá práva? Co je to licence? */}
          </Typography>
        </CardContent>
      </Card>
      
      <Box m={6} />
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar variant="rounded" className={classes.avatar}>
              II.
            </Avatar>
          }
          title="Umčo a právo II."
          subheader="14. 9. 2021, 18:30—20:30, Praha 1"
          // subheaderTypographyProps={{ className: classes.strike }}
        />
        <CardContent>
          <Typography variant="body2" component="p">
            {/* <b>ODLOŽENO</b>
            <br />
            <br /> */}
            Autorské právo pro pokročilé
            <br />
            {/* <br />
            Kdy můžu použít cizí dílo? Kdy mohou jiní použít mé dílo? Jaké jsou
            právní limity tvorby (osobní údaje, ochranné známky)? */}
          </Typography>
        </CardContent>
      </Card>
      <Box m={6} />
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar variant="rounded" className={classes.avatar}>
              III.
            </Avatar>
          }
          title="Umčo a právo III."
          subheader="21. 9. 2021, 18:30—20:30, Praha 1"
          // subheaderTypographyProps={{ className: classes.strike }}
        />
        <CardContent>
          <Typography variant="body2" component="p">
            {/* <b>ODLOŽENO</b>
            <br />
            <br /> */}
            Tvůrce a smlouvy
            <br />
            {/* <br />
            Musím něco podepsat, abych uzavřel smlouvu? Tvořím jako zaměstnanec,
            tvořím na IČO – je v tom rozdíl? */}
          </Typography>
        </CardContent>
      </Card>

      <Box m={6} />

      {/* <Typography>
          Když nám necháte svůj e-mail, dáme Vám vědět, až se otevře
          přihlašování:
        </Typography>
        <ValidatorForm onSubmit={this.handleSubmit} instantValidate={false}>
          <TextValidator
            ref={this.emailRef}
            label="Váš e-mail"
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            name="email"
            value={formData.email}
            validators={["required", "isEmail"]}
            errorMessages={[
              "Váš e-email je povinný.",
              "E-mail není ve správném formátu.",
            ]}
          />
          <br />
          <Button variant="contained" type="submit" disabled={submitted}>
            {(submitted && "Váš e-mail byl odeslán!") ||
              (!submitted && "Odeslat")}
          </Button>
        </ValidatorForm> */}
    </>
  );
  //}
};

export default KdySePotkame;
