import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import coverImgFullSize from "./assets/cover final.png";
import coverImgSm from "./assets/cover_final_50pc.png";
import coverImgMd from "./assets/cover_final_70.png";
import coverImgXs from "./assets/kytka.png";
import { Box, Hidden } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //border: "5px solid red",
  },
  content: {},
  coverImage: {},
}));

function Home() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Hidden lgDown>
          <img className={classes.coverImage} src={coverImgFullSize} />
        </Hidden>
        <Hidden only={["xs", "sm", "md", "xl"]}>
          <img className={classes.coverImage} src={coverImgMd} />
        </Hidden>
        <Hidden only={["xs", "lg", "xl"]}>
          <Box m={1} />
          <img className={classes.coverImage} src={coverImgSm} />
        </Hidden>
        <Hidden smUp>
          <Box m={1} />
          <img className={classes.coverImage} src={coverImgXs} />
        </Hidden>
      </div>
    </div>
  );
}

export default Home;
