import React, { useState, Component } from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";

import imgEHidden from "../assets/E_hidden_small.png";
import imgEShow from "../assets/E_show_small.jpg";
import imgPHidden from "../assets/P_hidden_small.png";
import imgPShow from "../assets/P_show_small.jpg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 225,
    height: 340,
    margin: 20,
  },
  imagesContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

const KdoJsme = () => {
  const classes = useStyles();

  const [imgEva, setImgEva] = useState(imgEHidden);
  const [imgPavlina, setImgPavlina] = useState(imgPHidden);

  return (
    <>
      <Box m={8} />
      <Typography variant="h5">KDO JSME?</Typography>

      <Box m={5} />

      <Typography>
        Jsme Eva a Pavlína, dvě mladé právničky. Denně vidíme, jak málo tvůrci
        ví o svých právech a možnostech, jak svá práva uplatnit nebo bránit.
        Chceme to změnit, a proto jsme nachystaly přednášky, které vám pomohou
        zorientovat se v právním bludišti.
      </Typography>
      <Box m={4} />
      <Typography>
        Po pár letech v praxi už víme dost, abychom vám mohly předat právní
        základy. Eva připravuje smlouvy a vysvětluje
        předpisy kreativcům a jiným právním laikům. Pavlína se ve státní správě
        setkává se zástupci nejrůznějších uměleckých profesí a proces tvorby
        zákonů zná jako své boty.
      </Typography>
      <Box m={4} />
      <Typography>
        Chceme vás poznat a myslíme si, že vy byste měli poznat nás.{" "}
        <Link to="/kdysepotkame"> Kdy se potkáme?</Link>
      </Typography>
      <Box m={4} />

      <Box className={classes.imagesContainer}>
        <img
          className={classes.image}
          src={imgEva}
          onMouseEnter={() => {
            setImgEva(imgEShow);
          }}
          onMouseOut={() => {
            setImgEva(imgEHidden);
          }}
        />

        <img
          className={classes.image}
          src={imgPavlina}
          onMouseEnter={() => {
            setImgPavlina(imgPShow);
          }}
          onMouseOut={() => {
            setImgPavlina(imgPHidden);
          }}
        />
      </Box>
    </>
  );
};

export default KdoJsme;
