import React from "react";
import {
  Grid,
  makeStyles,
  IconButton,
  Button,
  Typography,
} from "@material-ui/core";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footerStyle: {
    backgroundColor: "#29AB87",
    height: "300px",
    left: "0",
    bottom: "0",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  whiteStyle: {
    color: "white",
  },
  lastMenuItem: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  firstMenuItem: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  footerContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

function Footer() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.footerStyle}>
        <Grid container spacing={0}>
          <Grid item xs={false} md={1} xl={3} />
          <Grid item container xs={12} md={10} xl={6}>
            <Grid item xs={12} md={6} className={classes.firstMenuItem}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <IconButton className={classes.whiteStyle}>
                  <HomeOutlinedIcon fontSize="large" />
                </IconButton>
              </Link>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/copak.umcoapravo/"
                style={{ textDecoration: "none" }}
              >
                <IconButton className={classes.whiteStyle}>
                  <FacebookIcon fontSize="large" />
                </IconButton>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/copak_umcoapravo/"
                style={{ textDecoration: "none" }}
              >
                <IconButton className={classes.whiteStyle}>
                  <InstagramIcon fontSize="large" />
                </IconButton>
              </a>
            </Grid>
            <Grid item xs={12} md={6} className={classes.lastMenuItem}>
              <Link to="/pravninezbytnosti" style={{ textDecoration: "none" }}>
                <Button>
                  <Typography variant="h6" className={classes.whiteStyle}>
                    PRÁVNÍ NEZBYTNOSTI
                  </Typography>
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={false} md={1} xl={3} />
        </Grid>
      </div>
    </>
  );
}

export default Footer;
