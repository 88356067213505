import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  blueText: {
    color: "#29AB87",
  },
}));

const PravniNezbytnosti = () => {
  const classes = useStyles();

  return (
    <>
      <Box m={8} />
      <Typography variant="h5">PRÁVNÍ NEZBYTNOSTI </Typography>
      <Box m={5} />
      <Typography variant="h5">
        Informace o zpracování osobních údajů (GDPR info) <Box m={5} />
      </Typography>
      <Typography>
        Vaše osobní údaje (třeba jméno, příjmení, e-mailová adresa či jiné
        osobní údaje, které nám o sobě sdělíte – například když nám napíšete, co
        tvoříte nebo jaký právní trabl jste v minulosti zažili) zpracováváme my,
        Eva Meixnerová a Pavlína Navrátilová. Budete-li mít jakékoliv dotazy,
        napište nám prosím e-mail na{" "}
        <span className={classes.blueText}> napis@co-pak.cz</span>.
        <Box m={5} />
        Všechny vaše osobní údaje budeme střežit jako oko (oči) v hlavě a
        zpracovávat je budeme pouze za účelem organizace přednášek co-pak. To
        znamená, že si informace, které nám pošlete, uložíme, a pošleme vám pár
        e-mailů (děkovný e-mail, organizační e-mail, připomínkový e-mail, e-mail
        s prosbou o zpětnou vazbu). Je možné, že vám později, až budeme chystat
        nové kolo přednášek, pošleme ještě jeden nebo dva informační e-maily
        (aby vám neuniklo, že se konají nové přednášky).
        <Box m={5} />
        Při zpracování osobních údajů se neobejdeme bez Google, jehož
        přihlašovací formulář používáme, a služby Webmail, našeho e-mailového
        klienta. Nikomu dalšímu ale vaše osobní údaje nepředáme.
        <Box m={5} />
        Pokud jste nás lajkli na Facebooku nebo nás sledujete na Instagramu,
        zpracování vašich osobních údajů (třeba vaše jméno, profilová fotografie
        apod.) se řídí podmínkami těchto sociálních sítí. Náš web nepoužívá
        žádné cookies.
        <Box m={5} />
        Vaše osobní údaje si necháme tak dlouho, jak bude potřeba, nejdéle však
        3 roky.
        <Box m={5} />
        Kdykoliv se náš můžete zeptat na další informace nebo požadovat, ať vaše
        údaje opravíme nebo smažeme. Máte-li podezření, že s vašimi osobními
        údaji nenakládáme tak, jak bychom měly, můžete si na nás stěžovat u
        Úřadu pro ochranu osobních údajů (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.uoou.cz/"
        >
          www.uoou.cz
        </a>
        ) – budeme ale rády, když se nejdříve ozvete nám na e-mail{" "}
        <span className={classes.blueText}> napis@co-pak.cz</span>.
        <Box m={8} />
        <Typography variant="h5">
          Podmínky používání webových stránek <Box m={5} />
        </Typography>
        Chovejte se slušně. Pokud se vám naše webovky nebo naše fotky líbí,
        zeptejte se, než je někde použijete. Náš e-mail prosím nezařazujte do
        marketingových databází (nechceme mít ve schránce každý den nabídky od
        nigerijského prince).
        <Box m={8} />
      </Typography>
    </>
  );
};

export default PravniNezbytnosti;
