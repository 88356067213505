import React from "react";
import { Route, HashRouter as Router, Switch, Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import { Grid, makeStyles } from "@material-ui/core";
import KdoJsme from "./pages/kdojsme";
import CoDelame from "./pages/CoDelame";
import Kontakt from "./pages/Kontakt";
import KdySePotkame from "./pages/KdySePotkame";
import PravniNezbytnosti from "./pages/PravniNezbytnosti";
import ScrollToTop from "./ScrollToTop";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    justifyContent: "space-between",
    //backgroundColor: "#dfe3e7",
  },
  footer: {},
  paddingOnSmDown: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
    },
  },
}));

function App() {
  const classes = useStyles();
  return (
    <Router>
      <div className={classes.footerContainer}>
        <div>
          <Header />
          <div className={classes.paddingOnSmDown}>
            <Grid container spacing={0}>
              <Grid item xs={false} md={1} xl={3} />
              <Grid item xs={12} md={10} xl={6}>
                <ScrollToTop />
                <Switch>
                  <Route path="/" exact component={Home}></Route>
                  <Route path="/kdojsme" exact component={KdoJsme}></Route>
                  <Route path="/codelame" exact component={CoDelame}></Route>
                  <Route
                    path="/kdysepotkame"
                    exact
                    component={KdySePotkame}
                  ></Route>
                  <Route path="/kontakt" exact component={Kontakt}></Route>
                  <Route
                    path="/pravninezbytnosti"
                    exact
                    component={PravniNezbytnosti}
                  ></Route>
                </Switch>
              </Grid>
              <Grid item xs={false} md={1} xl={3} />
            </Grid>
          </div>
        </div>
        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
